<template>
    <v-card>
        <v-card-text>
            <h2 class="mb-1" v-if="authUser.name">{{ authUser.name }}</h2>
            <h5
                class="text-uppercase font-weight-light"
                v-if="$_.get(authUser, 'company.name')"
            >
                {{ authUser.company.name }}
            </h5>
            <v-row class="justify-center align-stretch fill-height">
                <v-col cols="9">
                    <v-alert
                        prominent
                        icon="mdi-alert"
                        border="top"
                        dismissible
                        dark
                        color="warning darken-2"
                        >Your password expires in 7 days.</v-alert
                    >
                </v-col>
            </v-row>
            <v-row class="justify-center align-stretch fill-height">
                <v-col cols="7">
                    <template v-for="(item, index) in items">
                        <div :id="item.id" :key="index">
                            <component
                                :is="item.component"
                                :user="authUser"
                            ></component>
                        </div>
                    </template>
                </v-col>
                <v-col cols="2">
                    <psi-scroll-nav :items="items" :selected.sync="selected">
                    </psi-scroll-nav>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "account-profile",
    components: {
        UpdateProfile: () => import("./Profile/UpdateProfile.vue"),
        ChangePassword: () => import("./Profile/ChangePassword.vue"),
        RolePermissions: () => import("./Profile/RoleAndPermissions"),
        RingCentral: () => import("./Profile/RingCentral"),
        MyDetails: () => import("./Profile/MyDetails"),
        UpdateProperties: () => import("./Profile/UpdateProperties"),
    },
    data() {
        return {
            profile: {},
            items: this.$config("account.profileMenuItems"),
            selected: "",
        };
    },

    computed: {
        ...mapGetters("Users", ["authUser"]),
    },
    watch: {},
    methods: {
        input() {},
        update(event) {
            console.log(event);
        },
    },
};
</script>
<style scoped>
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _vm.authUser.name
            ? _c("h2", { staticClass: "mb-1" }, [
                _vm._v(_vm._s(_vm.authUser.name))
              ])
            : _vm._e(),
          _vm.$_.get(_vm.authUser, "company.name")
            ? _c("h5", { staticClass: "text-uppercase font-weight-light" }, [
                _vm._v(" " + _vm._s(_vm.authUser.company.name) + " ")
              ])
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "justify-center align-stretch fill-height" },
            [
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        prominent: "",
                        icon: "mdi-alert",
                        border: "top",
                        dismissible: "",
                        dark: "",
                        color: "warning darken-2"
                      }
                    },
                    [_vm._v("Your password expires in 7 days.")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "justify-center align-stretch fill-height" },
            [
              _c(
                "v-col",
                { attrs: { cols: "7" } },
                [
                  _vm._l(_vm.items, function(item, index) {
                    return [
                      _c(
                        "div",
                        { key: index, attrs: { id: item.id } },
                        [
                          _c(item.component, {
                            tag: "component",
                            attrs: { user: _vm.authUser }
                          })
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("psi-scroll-nav", {
                    attrs: { items: _vm.items, selected: _vm.selected },
                    on: {
                      "update:selected": function($event) {
                        _vm.selected = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }